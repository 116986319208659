body {
  color: $primary-color;
}

.page-content {
  border-top: 5px solid $primary-color;
}

.site-header {
  border-top: 10px solid $primary-color;
  border-bottom: 5px solid $primary-color;
}

.my-info {
  padding-top: 1.7em;
  background: #262335;
  border-top: 5px solid #ff901f;
  border-bottom: 5px solid #ff2975;

  a:hover {
    color: $secondary-color;
  }
}

.post {
  h1 {
    color: $primary-color;
    font-weight: bold;
  }
}

.post-list {
  h3 {
    font-weight: bold;
  }
}

.post-content {
  p {
    line-height: 1.7em;
    font-size: 16px;
  }
}
